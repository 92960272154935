import React from "react";
import { useAppDispatch, useAppSelector } from "store";

import {
    Box,
    Card,
    CardContent,
    ClickAwayListener,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import Spacer from "components/Spacer";

import { StoreGroup } from "modules/customer/insights/cost/storeGroups";
import { selectStoreGroups } from "modules/customer/insights/cost/costSlice";
import {
    selectCostCorrelationsStoreGroup,
    setCostCorrelationsStoreGroup,
} from "modules/customer/insights/cost/costDrivers/costDriversSlice";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(35),
        borderRadius: (props: any) => props["data-is-open"] ? "6px 6px 0px 0px" : "6px",
        cursor: "pointer"
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledListCard = withStyles(theme => ({
    root: {
        position: "absolute",
        top: 0,
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(35),
        maxHeight: theme.spacing(40),
        overflow: "auto",
        borderRadius: "0px 0px 6px 6px",
        borderTopStyle: "solid",
        zIndex: theme.zIndex.drawer
    }
}))(Card);

const CostCorrelationsStoreGroupFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const storeGroups = useAppSelector(selectStoreGroups).filter(storeGroup => storeGroup.name.toLocaleLowerCase() !== "unknown");
    const selectedStoreGroup = useAppSelector(selectCostCorrelationsStoreGroup);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = () => {
        setIsOpen(prevIsOpen => !prevIsOpen);
    };

    const handleClickAway = () => {
        setIsOpen(false);
    };

    const setStoreGroup = (storeGroup: StoreGroup) => {
        dispatch(setCostCorrelationsStoreGroup(storeGroup));
    };

    return (
        <>
            <StyledCard ref={anchorRef} elevation={0} onClick={handleClick} data-is-open={isOpen}>
                <StyledCardContent>
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle2" component="div">
                            Store group
                        </Typography>
                        <Spacer />
                        {isOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                    </Box>
                    <Box display="flex" alignItems="center" paddingTop={1} minHeight={theme.spacing(3.5)}>
                        <Typography variant="body2" component="div" color="textSecondary">
                            {selectedStoreGroup?.name}
                        </Typography>
                    </Box>
                </StyledCardContent>
            </StyledCard>
            <Box position="relative">
                {isOpen && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <StyledListCard>
                            <List>
                                {storeGroups.map((storeGroup, index) =>
                                    <ListItem
                                        key={index}
                                        dense
                                        divider
                                        button
                                        selected={storeGroup.name === selectedStoreGroup?.name}
                                        onClick={() => setStoreGroup(storeGroup)}
                                    >
                                        <ListItemText
                                            primary={storeGroup.name}
                                            primaryTypographyProps={{
                                                variant: "subtitle1",
                                                component: "div"
                                            }}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </StyledListCard>
                    </ClickAwayListener>
                )}
            </Box>
        </>
    );
};

export default CostCorrelationsStoreGroupFilter;
