import React from "react";

import { useTheme } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";

import StreetView from "./StreetView";

import mathUtils from "utils/mathUtils";
import numberFormatter from "utils/numberFormatter";

const Summary: React.FC = () => {
    const theme = useTheme();
    const store = useAppSelector(selectSelectedStoreByCostType);

    return (
        <Box>
            <>
                <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                    How does your {store?.storeName} store's performance compare to all your other stores?
                    Our radar charts visualise this comparison, and show which metrics have the largest impact on costs within the store.
                </Typography>
                <br />
                <br />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.text.disabled }}>
                            Group
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                            {store?.group}
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.text.disabled, "paddingTop": theme.spacing(0.5) }}>
                            Store size
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                            {store !== undefined ? numberFormatter.toDecimalPlaces(parseFloat(String(store.sizeInSquareFeet)), 0, false) : 0}ft<sup>2</sup>
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.text.disabled }}>
                            Employees
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                            {mathUtils.round(store?.numberOfEmployees ?? 0, 0)}
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.text.disabled, "paddingTop": theme.spacing(1) }}>
                            Location
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <StreetView />
                    </Grid>
                </Grid>
            </>
        </Box>
    );
};

export default Summary;
