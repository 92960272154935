import React from "react";

import { Grid } from "@material-ui/core";

import CostCorrelationsCostTypeFilter from "./CostCorrelationsCostTypeFilter";
import CostCorrelationsMetricFilter from "./CostCorrelationsMetricFilter";
import CostCorrelationsScatterChart from "./CostCorrelationsScatterChart";
import CostMeasureType from "./CostMeasureType";
import CostCorrelationsStoreGroupFilter from "./CostCorrelationsStoreGroupFilter";

const Correlations: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item md="auto">
                <CostCorrelationsStoreGroupFilter />
            </Grid>
            <Grid item md="auto">
                <CostCorrelationsCostTypeFilter />
            </Grid>
            <Grid item md="auto">
                <CostCorrelationsMetricFilter />
            </Grid>
            <Grid item md="auto">
                <CostMeasureType />
            </Grid>
            <Grid item xs={12}>
                <CostCorrelationsScatterChart />
            </Grid>
        </Grid>
    );
};

export default Correlations;
