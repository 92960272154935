import React from "react";

import { useTheme } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectSelectedStore } from "modules/customer/tools/product/productSlice";

import StreetView from "./StreetView";

import numberFormatter from "utils/numberFormatter";
import { selectRetailCentreClassification } from "modules/customer/tools/product/storeOpportunities/storeOpportunitiesSlice";

const Summary: React.FC = () => {
    const theme = useTheme();
    const selectedStore = useAppSelector(selectSelectedStore);
    const pitchType = useAppSelector(selectRetailCentreClassification);
    const formatColour = selectedStore?.format ? theme.palette.common.white : theme.palette.text.disabled;
    const pitchTypeColour = pitchType.length !== 0 ? theme.palette.common.white : theme.palette.text.disabled;

    if (!selectedStore) {
        return (<></>);
    }

    const formattedOpeningDateString =
        `${numberFormatter.toOrdinalNumber(selectedStore.openingDate.day)} ${selectedStore.openingDate.monthLong} ${selectedStore.openingDate.year}`;

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": theme.palette.text.secondary }}>
                        Opening date
                    </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                        {formattedOpeningDateString}
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": theme.palette.text.secondary, "paddingTop": theme.spacing(0.5) }}>
                        Store size
                    </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                        {numberFormatter.toDecimalPlaces(selectedStore?.sizeInSquareFeet, 0, false)}ft<sup>2</sup>
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": theme.palette.text.secondary }}>
                        Format
                    </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": formatColour }}>
                        {selectedStore?.format ?? "No data available"}
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": theme.palette.text.secondary }}>
                        Pitch type
                    </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": pitchTypeColour }}>
                        {pitchType.length !== 0 ? pitchType : "Loading..."}
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <Typography variant="subtitle1" style={{ "color": theme.palette.text.secondary, "paddingTop": theme.spacing(1) }}>
                        Location
                    </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                    <StreetView />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Summary;
