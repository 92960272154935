import React from "react";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectSelectedStore } from "modules/customer/tools/product/productSlice";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedStore = useAppSelector(selectSelectedStore);

    return (
        <>
            Identify sales opportunities by analysing historical trends and optimising
            the product mix to match local demand for the {selectedPartner?.name} {selectedStore?.name} store.
        </>
    );
};

export default Subtitle;
