import { createAppAsyncThunk } from "appThunk";
import { logError } from "modules/helpers/logger/loggerSlice";
import { Partner } from "./partner";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { ResultSet } from "@cubejs-client/core";
import { ProductSales } from "./productSales";
import { Store } from "./store";

export class Product {
    public readonly id: number;
    public readonly name: string;
    public readonly productCategory: string;
    public readonly sales: ProductSales;

    constructor(
        id: number,
        name: string,
        productCategory: string,
        clientSourcedSales: number,
        estimatedSales: number,
        optimisedSales: number,
        salesHeadroom: number,
        confidenceInterval?: number
    ) {
        this.id = id;
        this.name = name;
        this.productCategory = productCategory;
        this.sales = new ProductSales(
            String(id),
            name,
            clientSourcedSales,
            estimatedSales,
            optimisedSales,
            salesHeadroom,
            confidenceInterval
        );
    }
}

export const loadProducts = createAppAsyncThunk<
    Product[],
    { referenceDate: DateTime, partner?: Partner, selectedStore?: Store, productCategoryID?: number }
>(
    "customer/tools/product/loadProducts",
    async ({ referenceDate, partner, selectedStore, productCategoryID }, thunkAPI) => {
        try {
            if (!partner) {
                return [];
            }

            const startDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day');
            const endDate = referenceDate.endOf('day');
            const query = {
                measures: [
                    "ProductSalesEstimate.SumClientSourcedSales",
                    "ProductSalesEstimate.SumEstimatedSales",
                    "ProductSalesEstimate.SumOptimisedSales",
                    "ProductSalesEstimate.SumSalesHeadroom",
                    "ProductSalesEstimate.AvgConfidenceInterval"
                ],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [startDate, endDate]
                }],
                dimensions: [
                    "D_ProductCategory.PK_ProductCategory",
                    "D_ProductCategory.ProductCategory1",
                    "D_ProductCategory.ProductCategory2",
                ],
                filters: [{
                    member: "D_Store.Group",
                    operator: "equals",
                    values: [partner.name]
                }],
                segments: [
                    "D_Store.OpenPhysicalStores"
                ],
            };

            if (selectedStore) {
                query.filters.push({
                    member: "D_Store.StoreNaturalID",
                    operator: "equals",
                    values: [String(selectedStore.id)]
                });
            }

            if (productCategoryID) {
                query.filters.push({
                    member: "ProductSalesEstimate.FK_ProductCategory",
                    operator: "equals",
                    values: [String(productCategoryID)]
                });
            }

            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;
            const products = resultSet.rawData().map(row => new Product(
                Number(row["D_ProductCategory.PK_ProductCategory"]),
                row["D_ProductCategory.ProductCategory1"],
                row["D_ProductCategory.ProductCategory2"],
                Number(row["ProductSalesEstimate.SumClientSourcedSales"]),
                Number(row["ProductSalesEstimate.SumEstimatedSales"]),
                Number(row["ProductSalesEstimate.SumOptimisedSales"]),
                Number(row["ProductSalesEstimate.SumSalesHeadroom"]),
                Number(row["ProductSalesEstimate.AvgConfidenceInterval"])
            ));
            return products;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading stores.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
