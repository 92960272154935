import React from "react";
import StoreSalesCategoryMix from "./StoreSalesCategoryMix";
import EstimatedSalesMix from "./EstimatedSalesMix";
import { Grid } from "@material-ui/core";
import OptimisedSalesMix from "./OptimisedSalesMix";
import SalesByProduct from "./SalesByProduct";

const ProductMix: React.FC = () => {
    return (
        <Grid container spacing={3} >
            <Grid item md={12}>
                <StoreSalesCategoryMix />
            </Grid>
            <Grid item md={12}>
                <EstimatedSalesMix />
            </Grid>
            <Grid item md={12}>
                <OptimisedSalesMix />
            </Grid>
            <Grid item md={12}>
                <SalesByProduct />
            </Grid>
        </Grid>
    );
};

export default ProductMix;
