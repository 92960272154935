import React from "react";

import { useAppSelector } from "store";
import { selectEstimatedSalesMix } from "modules/customer/tools/product/storeOpportunities/storeOpportunitiesSlice";
import SalesMixTreemap from "./SalesMixTreemap";
import { Box, Typography } from "@material-ui/core";

const EstimatedSalesMix: React.FC = () => {
    const { isLoading, hasErrors, data } = useAppSelector(selectEstimatedSalesMix);

    return (
        <Box data-cy="estimated-sales-mix-treemap">
            <Typography variant="h6" component="div" gutterBottom>
                Current estimated sales mix
            </Typography>
            <SalesMixTreemap
                isLoading={isLoading}
                hasErrors={hasErrors}
                salesTypeName="Current estimated"
                data={data}
            />
        </Box>
    );
};

export default EstimatedSalesMix;
