import { combineReducers } from "redux";

import slice from "./productSlice";
import partnerFilters from "./partnerFilters";
import storeProductFilters from "./storeProductFilters";
import storeOpportunities from "./storeOpportunities/index";

const reducer = combineReducers({
    root: slice.reducer,
    partnerFilters,
    storeProductFilters,
    storeOpportunities
});

export default reducer;
