import { Box, Typography, useTheme, withStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { DataGridPro, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import Spacer from "components/Spacer";
import { selectUserInfo } from "modules/auth/authSlice";
import { selectFlattenedProducts, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import React from "react";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledDataGrid = withStyles(theme => ({
    root: {
        border: 'none',
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        '& .MuiCheckbox-root': {
            color: theme.palette.text.primary,
            '&.Mui-checked': {
                //@ts-ignore
                color: theme.palette.tertiary.main,
            },
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.16)',
            },
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none'
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
        }
    }
}))(DataGridPro);

const SalesByProduct: React.FC = () => {
    const theme = useTheme();
    const userInfo = useAppSelector(selectUserInfo);
    const { data: rows, isLoading } = useAppSelector(selectFlattenedProducts);
    const selectedStore = useAppSelector(selectSelectedStore);

    const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 1,
            headerName: 'Product',
            sortable: true,
        },
        {
            field: 'productCategory',
            flex: 1,
            headerName: 'Product category',
            sortable: true,
            hide: true
        },
        {
            field: 'optimisedSales',
            type: 'number',
            flex: 1,
            headerName: 'Optimised sales',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'estimatedSales',
            type: 'number',
            flex: 1,
            headerName: 'Estimated sales',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'clientSourcedSales',
            type: 'singleSelect',
            flex: 1,
            headerName: `${userInfo.companyDisplayName} sourced sales`,
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'salesHeadroom',
            type: 'number',
            flex: 1,
            headerName: 'Headroom',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        // {
        //     field: 'confidenceInterval',
        //     type: 'number',
        //     flex: 1,
        //     headerName: 'Confidence interval',
        //     sortable: true,
        //     renderCell: (params) => numberFormatter.toPercentage(params.value ?? 0, true, 0),
        // },
    ];

    const columndsWithWidth = columns.map(column => {
        if (column.type === 'singleSelect') {
            //@ts-expect-error
            column.valueOptions = Array.from(new Set(rows.map(row => row[column.field])));
        }
        return {
            ...column
        };
    });

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ paddingRight: theme.spacing(2) }}>
                <GridToolbarColumnsButton style={{ color: theme.palette.text.primary }} />
                <GridToolbarFilterButton style={{ color: theme.palette.text.primary }} />
                <Spacer />
                {/* @ts-ignore */}
                <GridToolbarExport style={{ color: theme.palette.tertiary.main }} />
            </GridToolbarContainer>
        );
    }

    return (
        <Box display="flex" flexDirection="column" >
            <Box flexGrow={1} width='100%' height={'60vh'} >
                <Typography variant="h6" component="div" gutterBottom>
                    {selectedStore?.name} optimised and estimated sales by product
                </Typography>
                <StyledDataGrid
                    rows={rows}
                    columns={columndsWithWidth}
                    checkboxSelection
                    disableSelectionOnClick
                    loading={isLoading}
                    components={{
                        Toolbar: CustomToolbar,
                        ColumnSortedAscendingIcon: ExpandLess,
                        ColumnSortedDescendingIcon: ExpandMore
                    }}
                    sortingOrder={['asc', 'desc']}
                    hideFooter
                />
            </Box>
        </Box>
    );
};

export default SalesByProduct;
