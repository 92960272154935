import React from "react";

import { Box, Typography } from "@material-ui/core";
import { useAppSelector } from "store";
import { selectSelectedPartner } from "modules/customer/tools/product/productSlice";

const Title: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    return (
        <>
            <Typography variant="h5" component="div">
                Store selection
            </Typography>
            <Box paddingTop={2}>
                <Typography variant="subtitle1" component="div">
                    Explore headroom potential for each {selectedPartner?.name} store
                    and use filters to identify locations where optimising the
                    product range could drive the most growth.
                </Typography>
            </Box>
        </>
    );
};

export default Title;
