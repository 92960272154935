import React from "react";

import { Box, Card, CardMedia, Fade, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

const StyledImageCard = withStyles(() => ({
    root: {
        position: "absolute",
        top: 0,
        backgroundColor: "transparent",
        zIndex: (props) => props["data-selected"] ? 200 : 100
    }
}))(Card);

const StyledReferenceCard = withStyles(() => ({
    root: {
        opacity: 0
    }
}))(Card);

const PowerfulAlgorithms = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedImage, setSelectedImage] = React.useState(0);
    const images = [{
        url: "/content/landing/home/powerful-algorithms/area-health.png",
        alt: "area health"
    }, {
        url: "/content/landing/home/powerful-algorithms/demand.png",
        alt: "demand"
    }, {
        url: "/content/landing/home/powerful-algorithms/competition.png",
        alt: "competition"
    }];

    React.useEffect(() => {
        const interval = setInterval(() => {
            setSelectedImage(prevSelectedImage => (prevSelectedImage + 1) % 3);
        }, 4500);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <PageSection
            bgcolor={theme.palette.common.white}
            dataCy="pages-landing-home-powerful-algorithms"
        >
            <Typography variant={isMobile ? "h4" : "h2"} component="div" align="center" gutterBottom>
                Powerful AI algorithms to analyse your stores, customer buying habits, and evaluate future demand for your products
            </Typography>
            <br />
            <br />
            <br />
            <Box position="relative" align="center">
                <StyledReferenceCard square elevation={0}>
                    <CardMedia component="img" image={images[0].url} alt={images[0].alt} />
                </StyledReferenceCard>
                {images.map((image, index) =>
                    <Fade
                        key={index}
                        in={selectedImage === index}
                        timeout={{
                            enter: 1500,
                            exit: 3000
                        }}
                    >
                        <StyledImageCard square elevation={0} data-selected={selectedImage === index}>
                            <CardMedia component="img" image={image.url} alt={image.alt} />
                        </StyledImageCard>
                    </Fade>
                )}
            </Box>
        </PageSection>
    );
};

export default PowerfulAlgorithms;
