import React from "react";

import { Grid } from "@material-ui/core";

import CostDriversCostTypeFilter from "./CostDriversCostTypeFilter";
import CostDriversStoreGroupFilter from "./CostDriversStoreGroupFilter";
import CostDriversLineChart from "./CostDriversLineChart";

const CostDrivers: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <CostDriversCostTypeFilter />
            </Grid>
            <Grid item xs={8}>
                <CostDriversStoreGroupFilter />
            </Grid>
            <Grid item xs={12}>
                <CostDriversLineChart />
            </Grid>
        </Grid>
    );
};

export default CostDrivers;
