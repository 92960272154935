import { AppThunk } from "appThunk";
import { cubeLoadExtended, ExtendedResultSet } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class SimilarityMetricValue {
    public readonly id: string;
    public readonly storeId: number;
    public readonly metricNameId: number;
    public readonly metricValue: number;

    constructor(
        storeId: number,
        metricNameId: number,
        metricValue: number
    ) {
        this.id = `${storeId}-${metricNameId}`;
        this.storeId = storeId;
        this.metricNameId = metricNameId;
        this.metricValue = metricValue;
    }
}

export const loadSimilarityMetricValues = (): AppThunk<Promise<SimilarityMetricValue[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "SimilarityMetric.StoreId",
                "SimilarityMetric.FK_SimilarityMetricName",
                "SimilarityMetric.MetricValue"
            ]
        };
        const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;

        return resultSet.loadResponses[0].data.map(row => {
            return new SimilarityMetricValue(
                Number(row["SimilarityMetric.StoreId"]),
                Number(row["SimilarityMetric.FK_SimilarityMetricName"]),
                Number(row["SimilarityMetric.MetricValue"])
            );
        });
    } catch (error) {
        dispatch(logError("Error loading SimilarityMetricValues.", error));
        throw error;
    }
};
