import React from "react";

import { Box } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import Radar from "components/visuals/Radar";

import { useAppSelector } from "store";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";
import { selectStoreVsComparatorPerformance } from "modules/customer/insights/cost/storeCosts/storeCostsSlice";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const formatMetricName = (metricName: string): string => {
    if (metricName === "Sqft") {
        return "Store size";
    }
    else if (metricName === "NumberOfCarParkSpace") {
        return "Car Park";
    }
    else if (metricName === "storeAgeDays") {
        return "Store age";
    }
    else if (metricName === "competitorSqft") {
        return "Competitor store size";
    }
    else if (metricName === "footfallDensityLTM") {
        return "Footfall";
    }

    return metricName;
};

const StoreVsComparatorPerformance = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectSelectedStoreByCostType);
    const storeVsComparatorPerformance = useAppSelector(selectStoreVsComparatorPerformance);

    const options = {
        xAxis: {
            type: "category",
            tickmarkPlacement: "on",
            lineWidth: 0
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            max: 5
        },
        colors: colourPalette.comparators,
        legend: {
            enabled: true
        },
        tooltip: {
            headerFormat: ``,
            footerFormat: ``,
            useHTML: true,
            formatter: function (): any {
                // @ts-ignore
                const points = this.points;
                const series = points[0].series;
                const seriesName = series.name;
                const seriesColor = series.color;

                const otherSeries = points[1].series;
                const otherSeriesName = otherSeries?.name;
                const otherSeriesColor = otherSeries?.color;

                const categoryArr = [seriesName, otherSeriesName];
                const categoryFormatArr = [`color:${seriesColor};font-weight:bold`, `color:${otherSeriesColor};font-weight:bold`];

                const valueArr = [
                    numberFormatter.toDecimalPlaces(points[0].point.custom.tooltipValue, 1),
                    numberFormatter.toDecimalPlaces(points[1].point.custom.tooltipValue, 1)
                ];

                const headerRow = points[0].key;

                return `<table>${stringUtils.tooltipHTML(categoryArr, {
                    values: valueArr,
                    header: headerRow,
                    categoryFormattingArr: categoryFormatArr
                })}</table>`;
            }
        },
        series: [{
            name: selectedStore?.storeName,
            data: storeVsComparatorPerformance?.data.store.map(data => {
                return {
                    name: formatMetricName(data.metricName),
                    y: data.metricValue
                };
            }),
            pointPlacement: "on",
            zIndex: 1
        }, {
            name: "Cluster Average",
            data: storeVsComparatorPerformance?.data.clusterAverage.map(data => {
                return {
                    name: formatMetricName(data.metricName),
                    y: data.metricValue
                };
            }),
            pointPlacement: "on"
        }]
    };

    return (
        <Box data-cy="store-vs-comparator-performance">
            <Radar loading={storeVsComparatorPerformance?.isLoading} error={storeVsComparatorPerformance?.hasErrors} options={options} dataAdditionPercent={10} />
        </Box>
    );
};

export default StoreVsComparatorPerformance;
