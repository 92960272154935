import React from "react";

import { Card, CardActionArea, CardMedia, Dialog, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";
import VideoPlayer from "components/VideoPlayer";

import { selectAzureCdnConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const StyledLogoCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent",
        width: "320px"
    }
}))(Card);

const Testimonials: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const azureCdnConfiguration = useAppSelector(selectAzureCdnConfiguration);
    const nwaaTestimonialVideoUrl = azureCdnConfiguration.nwaaTestimonialVideoUrl;
    const [showVideo, setShowVideo] = React.useState(false);
    const videoJsOptions = { //Reference https://docs.videojs.com/tutorial-options.html for more options
        autoplay: true,
        controls: true,
        nativeTextTracks: false,
        preloadTextTracks: false,
        responsive: true,
        fluid: true,
        controlBar: {
            pictureInPictureToggle: false
        },
        sources: [{
            src: nwaaTestimonialVideoUrl,
            type: "video/mp4"
        }],
        poster: "/content/landing/home/testimonials/nwaa-testimonial-video-poster.jpg",
        crossorigin: "anonymous"
    };

    const handleShowVideoClick = () => {
        setShowVideo(true);
    };

    const handleCloseVideoClick = () => {
        setShowVideo(false);
    };

    return (
        // @ts-ignore
        <PageSection disablePaddingTop bgcolor={theme.palette.common.white} dataCy="pages-landing-home-testimonials">
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} md={6}>
                    <StyledCard square elevation={0}>
                        <CardActionArea onClick={handleShowVideoClick}>
                            <CardMedia component="img" image="/content/landing/home/testimonials/nwaa-testimonial-video-poster-with-play-button.jpg" alt="nwaa testimonial" />
                        </CardActionArea>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledLogoCard square elevation={0}>
                        <CardMedia component="img" image="/content/landing/home/testimonials/nwaa-logo.jpg" alt="nwaa logo" />
                    </StyledLogoCard>
                    <br /><br />
                    <Typography variant={isMobile ? "h4" : "h3"} component="div">
                        Dash has become indispensable for us.
                        Our decision making relies heavily on its data-driven insights.
                        Our board frequently discusses its insights on location effectiveness.
                    </Typography>
                    <br /><br />
                    <Typography variant={isMobile ? "body2" : "subtitle1"} component="div">
                        Reuben Phillips
                        <br />
                        Retail Area Manager, North West Air Ambulance Charity
                    </Typography>
                </Grid>
            </Grid>
            <Dialog
                open={showVideo}
                onClose={handleCloseVideoClick}
                maxWidth="lg"
                fullWidth
            >
                {/* @ts-ignore */}
                <VideoPlayer options={videoJsOptions} onClose={handleCloseVideoClick} />
            </Dialog>
        </PageSection>
    );
};

export default Testimonials;
