import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";

import { useAppDispatch } from "store";
import { openStreetView } from "modules/customer/tools/product/productSlice";

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        // @ts-ignore
        backgroundColor: theme.palette.background.insight,
        "&:hover": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
            // @ts-ignore
            backgroundColor: theme.palette.background.insight,
        }
    }
}))(Button);

const StreetView: React.FC = () => {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(openStreetView());
    };

    return (
        <StyledButton
            variant="text"
            size="medium"
            color="default"
            disableElevation
            startIcon={<LaunchRoundedIcon />}
            onClick={handleClick}
            data-cy="btn-store-summary-open-street-view"
        >
            Open street view
        </StyledButton>
    );
};

export default StreetView;
