import React from "react";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectSelectedStore } from "modules/customer/tools/product/productSlice";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedStore = useAppSelector(selectSelectedStore);

    return (
        <>
            Understand the product categories driving sales at the {selectedPartner?.name} {selectedStore?.name} store.
            Pinpoint categories with the highest headroom to fine-tune inventory, align product range with local demand,
            and enhance sales opportunities.
        </>
    );
};

export default Subtitle;
