import React from "react";

import { useAppDispatch, useAppSelector } from "store";
import { Box, Card, CardActionArea, CardContent, IconButton, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { useTheme, withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";
import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";
import Spacer from "components/Spacer";

import { clearCandidateStore, selectStoreProductFiltersVisibility, selectTooltipHintText, showStoreProductFilters } from "modules/customer/tools/product/storeProductFilters/storeProductFiltersSlice";
import { ProductChapter, clearSelectedStore, selectCurrentChapter, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { selectPartnerFiltersVisibility } from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.insight,
        borderRadius: 6
    }
}))(Card);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        minHeight: theme.spacing(10)
    }
}))(CardActionArea);

const Open: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectSelectedStore);
    const currentChapter = useAppSelector(selectCurrentChapter);
    const partnerFiltersVisibility = useAppSelector(selectPartnerFiltersVisibility);
    const storeProductFiltersVisibility = useAppSelector(selectStoreProductFiltersVisibility);
    const tooltipText = useAppSelector(selectTooltipHintText);

    const filtersAreVisible = partnerFiltersVisibility.isVisible || storeProductFiltersVisibility.isVisible;
    const filterRequiredChapters = [ProductChapter.StoreOpportunities, ProductChapter.ProductOpportunities, ProductChapter.ProductStoreFit];
    const isTooltipOpen = filterRequiredChapters.includes(currentChapter) && !filtersAreVisible && !selectedStore;

    const handleClickOpenModal = () => {
        dispatch(showStoreProductFilters());
    };

    const handleClickClearStore = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch(clearSelectedStore());
        dispatch(clearCandidateStore());
    };

    return (
        <SimpleTooltip
            placement={SimpleTooltipPlacement.Bottom}
            title={
                <Typography variant="subtitle1" component="div">
                    {tooltipText}
                </Typography>
            }
            open={isTooltipOpen}
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
        >
            <StyledCard
                elevation={0}
                variant="outlined"
                data-cy="btn-filters"
            >
                <StyledCardActionArea onClick={handleClickOpenModal}>
                    <CardContent>
                        <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1" component="div">
                                Store:&nbsp;&nbsp;
                            </Typography>
                            {selectedStore ? (
                                <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                                    {selectedStore?.name}
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div" style={{ color: theme.palette.text.secondary }}>
                                    Unselected
                                </Typography>
                            )}
                            {selectedStore && (<>
                                <Spacer />
                                <IconButton
                                    size="small"
                                    onClick={handleClickClearStore}
                                >
                                    <Close />
                                </IconButton>
                            </>)
                            }
                        </Box>
                        {/* <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1" component="div">
                                Product:&nbsp;&nbsp;
                            </Typography>
                            {selectedProduct ? (
                                <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                                    {selectedProduct?.name}
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div" style={{ color: theme.palette.text.secondary }}>
                                    Unselected
                                </Typography>
                            )}
                            {selectedProduct && (<>
                                <Spacer />
                                <IconButton
                                    size="small"
                                    onClick={handleClickClearProduct}
                                >
                                    <Close />
                                </IconButton>
                            </>)
                            }
                        </Box> */}
                    </CardContent>
                </StyledCardActionArea>
            </StyledCard>
        </SimpleTooltip>
    );
};

export default Open;
