import React from "react";
import Highcharts, { dateFormat } from "highcharts";

import { Box } from "@material-ui/core";

import StockLine from "components/visuals/StockLine";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";
import {
    CostMeasure,
    CostsOverTimeLineChartGranularity,
    selectCostMeasure,
    selectCostsOverTime,
    setCostsOverTimeLineChartGranularity,
    selectCostsOverTimeLineChartGranularity
} from "modules/customer/insights/cost/storeCosts/storeCostsSlice";

import { DateTime } from "luxon";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const CostsOverTimeLineChart: React.FC = () => {
    const dispatch = useAppDispatch();
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectSelectedStoreByCostType);
    const costMeasure = useAppSelector(selectCostMeasure);
    const costsOverTime = useAppSelector(selectCostsOverTime);
    const chartGranularity = useAppSelector(selectCostsOverTimeLineChartGranularity);

    const series: Highcharts.Series[] = [];
    const navigationSeries: any[] = [];

    series.push({
        name: `${selectedStore?.storeName}`,
        // @ts-ignore
        data: costsOverTime.data.store.map(store =>
            [DateTime.fromISO(store.date.toISODate(), { zone: "utc" }).toMillis(), (costMeasure === CostMeasure.CostValue ? store.costValue : store.costAsPercentageOfRevenue)]
        ),
        color: colourPalette.comparators[0]
    });

    navigationSeries.push({
        type: "spline",
        data: costsOverTime.data.store.map(store =>
            [DateTime.fromISO(store.date.toISODate(), { zone: "utc" }).toMillis(), (costMeasure === CostMeasure.CostValue ? store.costValue : store.costAsPercentageOfRevenue)]
        )
    });

    series.push({
        name: `Cluster average`,
        // @ts-ignore
        data: costsOverTime.data.clusterAverage.map(store =>
            [DateTime.fromISO(store.date.toISODate(), { zone: "utc" }).toMillis(), (costMeasure === CostMeasure.CostValue ? store.costValue : store.costAsPercentageOfRevenue)]
        ),
        color: colourPalette.comparators[1]
    });

    navigationSeries.push({
        type: "spline",
        data: costsOverTime.data.clusterAverage.map(store =>
            [DateTime.fromISO(store.date.toISODate(), { zone: "utc" }).toMillis(), (costMeasure === CostMeasure.CostValue ? store.costValue : store.costAsPercentageOfRevenue)]
        )
    });

    const options = {
        yAxis: {
            title: {
                text: (costMeasure === CostMeasure.CostValue ? "Costs" : "Cost as a % of revenue")
            },
            labels: {
                //@ts-ignore
                formatter: function () {
                    return (
                        costMeasure === CostMeasure.CostValue ?
                            //@ts-ignore
                            numberFormatter.toGBP(this.value, 0) :
                            //@ts-ignore
                            numberFormatter.toPercentage(this.value, true, 0)
                    );
                }
            }
        },
        legend: {
            enabled: true
        },
        series,
        navigator: {
            series: navigationSeries,
        },
        rangeSelector: {
            allButtonsEnabled: true,
            selected: 0,
            buttons: [{
                type: "month",
                count: 12,
                text: "Month",
                dataGrouping: {
                    forced: true,
                    units: [["month", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                events: {
                    click: function () {
                        dispatch(setCostsOverTimeLineChartGranularity(CostsOverTimeLineChartGranularity.Month));
                    }
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 24,
                text: "Quarter",
                dataGrouping: {
                    forced: true,
                    units: [["month", [3]]],
                    approximation: "sum",
                    groupAll: true
                },
                events: {
                    click: function () {
                        dispatch(setCostsOverTimeLineChartGranularity(CostsOverTimeLineChartGranularity.Quarter));
                    }
                },
                preserveDataGrouping: true
            }, {
                type: "year",
                count: 3,
                text: "Year",
                dataGrouping: {
                    forced: true,
                    units: [["year", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                events: {
                    click: function () {
                        dispatch(setCostsOverTimeLineChartGranularity(CostsOverTimeLineChartGranularity.Year));
                    }
                },
                preserveDataGrouping: true
            }]
        },
        tooltip: {
            enabled: true,
            shared: true,
            headerFormat: ``,
            footerFormat: ``,
            // @ts-ignore
            formatter: function () {
                let header = "";
                if (chartGranularity === CostsOverTimeLineChartGranularity.Year) {
                    // @ts-ignore
                    header = dateFormat("%Y", this.points[0].x);
                }
                else if (chartGranularity === CostsOverTimeLineChartGranularity.Month) {
                    // @ts-ignore
                    header = dateFormat("%B %Y", this.points[0].x);
                }
                else {
                    // @ts-ignore
                    const month = DateTime.fromMillis(this.points[0].x).month;
                    const quarter = Math.ceil(month / 3);
                    // @ts-ignore
                    header = `Q${quarter} ` + dateFormat("%Y", this.points[0].x);
                }

                const categoriesArr: string[] = [];
                const categoriesFormatArr: string[] = [];
                const valuesArr: string[] = [];

                // @ts-ignore
                this.points.forEach((point, index) => {
                    categoriesArr.push(point.series.name, (costMeasure === CostMeasure.CostValue ? "Cost value" : "Cost as % revenue"));
                    categoriesFormatArr.push(`color:${point.series.color};font-weight:bold`, "");

                    const value = costMeasure === CostMeasure.CostValue ? numberFormatter.toGBP(point.y) : numberFormatter.toPercentage(point.y, true, 1);

                    valuesArr.push(
                        "",
                        value
                    );
                });

                return `<table>${stringUtils.tooltipHTML(
                    categoriesArr,
                    {
                        header: header,
                        values: valuesArr,
                        categoryFormattingArr: categoriesFormatArr
                    }
                )}</table>`;
            },
            useHTML: true
        }
    };

    return (
        <Box data-cy="labour-costs-over-time">
            <StockLine loading={costsOverTime.isLoading} error={costsOverTime.hasErrors} options={options} globalOptions={{}} />
        </Box>
    );
};

export default CostsOverTimeLineChart;
