import React from "react";

import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Highcharts from "highcharts";
import Column from "components/visuals/Column";

import { useAppSelector } from "store";
import {
    selectIsLoading,
    selectHasErrors,
    selectCostDrivers,
    selectCostDriversCostType
} from "modules/customer/insights/cost/costDrivers/costDriversSlice";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const formatMetricName = (metricName: string): string => {
    if (metricName === "Sqft") {
        return "Store size";
    }
    else if (metricName === "NumberOfCarParkSpace") {
        return "Car Park";
    }
    else if (metricName === "storeAgeDays") {
        return "Store age";
    }
    else if (metricName === "competitorSqft") {
        return "Competitor store size";
    }
    else if (metricName === "footfallDensityLTM") {
        return "Footfall";
    }

    return metricName;
};

const CostDriversLineChart: React.FC = () => {
    const theme = useTheme();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const costDrivers = useAppSelector(selectCostDrivers);
    const costType = useAppSelector(selectCostDriversCostType);

    const seriesPositiveValues: (number | null)[] = [];
    const seriesNegativeValues: (number | null)[] = [];
    costDrivers.data.forEach(data => {
        if (data.correlationValue > 0) {
            seriesPositiveValues.push(data.correlationValue);
            seriesNegativeValues.push(null);
        }
        else {
            seriesPositiveValues.push(null);
            seriesNegativeValues.push(data.correlationValue);
        }
    });

    const options: Highcharts.Options = {
        xAxis: {
            categories: costDrivers.data.map(data => formatMetricName(data.metric))
        },
        yAxis: {
            title: {
                text: `Impact on ${costType?.name.toLocaleLowerCase()} cost`
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: false
                },
                custom: {
                    printMe: true
                },
                centerInCategory: true
            }
        },
        tooltip: {
            enabled: true,
            shared: true,
            headerFormat: ``,
            footerFormat: ``,
            formatter: function () {
                const points = this.points ?? [];

                let series: Highcharts.Series;
                let point: Highcharts.TooltipFormatterContextObject;
                let categoryArr: string[] = [];
                let categoryFormatArr: string[] = [];
                let valueFormatArr: string[] = [];
                let valuesArr: string[] = [];

                for (let i = 0; i < points.length; i++) {
                    point = points[i];
                    series = point.series;
                    categoryArr.push(series?.name, "Correlation");
                    categoryFormatArr.push(`color:${series?.options?.color};font-weight:bold`, "");
                    valueFormatArr.push("", `color:${series?.options?.color};font-weight:bold`);
                    valuesArr.push("", numberFormatter.toDecimalPlaces(point.y, 2));
                }

                return `<table>${stringUtils.tooltipHTML(categoryArr, {
                    header: points[0].key,
                    values: (valuesArr),
                    categoryFormattingArr: categoryFormatArr,
                    valueFormattingArr: valueFormatArr
                })}</table>`;
            },
            useHTML: true
        },
        series: [{
            type: "column",
            name: "Positive correlation",
            data: seriesPositiveValues,
            color: theme.palette.success.main
        }, {
            type: "column",
            name: "Negative correlation",
            data: seriesNegativeValues,
            color: theme.palette.error.main
        }]
    };

    return (
        <Box data-cy="total-trend-cost-over-time">
            <Typography variant="h6" component="div" gutterBottom>
                Total cost trends over time
            </Typography>
            <Column loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default CostDriversLineChart;
