import React from "react";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectSelectedStore } from "modules/customer/tools/product/productSlice";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedStore = useAppSelector(selectSelectedStore);

    return (
        <>
            Uncover sales potential for the {selectedPartner?.name} {selectedStore?.name} store by
            tracking historical trends in estimated and optimised sales. Identify key periods
            where aligning the product range with demand could capture additional revenue and
            maximise store performance.
        </>
    );
};

export default Subtitle;
