import { createAppAsyncThunk } from "appThunk";
import { logError } from "modules/helpers/logger/loggerSlice";
import { Partner } from "./partner";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { ResultSet } from "@cubejs-client/core";
import { ProductSales } from "./productSales";

export class Store {
    public readonly id: string;
    public readonly name: string;
    public readonly address: string;
    public readonly region: string;
    public readonly clientRegion: string;
    public readonly retailCentreID: number;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly openingDate: DateTime;
    public readonly sizeInSquareFeet: number;
    public readonly format: string | null;
    public readonly sales: ProductSales;

    constructor(
        id: string,
        name: string,
        address: string,
        region: string,
        clientRegion: string,
        retailCentreID: number,
        latitude: number,
        longitude: number,
        openingDate: DateTime,
        sizeInSquareFeet: number,
        format: string | null,
        clientSourcedSales: number,
        estimatedSales: number,
        optimisedSales: number,
        salesHeadroom: number,
        confidenceInterval: number
    ) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.region = region;
        this.clientRegion = clientRegion;
        this.retailCentreID = retailCentreID;
        this.latitude = latitude;
        this.longitude = longitude;
        this.openingDate = openingDate;
        this.sizeInSquareFeet = sizeInSquareFeet;
        this.format = format;
        this.sales = new ProductSales(
            id,
            name,
            clientSourcedSales,
            estimatedSales,
            optimisedSales,
            salesHeadroom,
            confidenceInterval
        );
    }
}

export const loadStores = createAppAsyncThunk<
    Store[],
    { referenceDate: DateTime, partner?: Partner, selectedStore?: Store, productCategoryID?: number }
>(
    "customer/tools/product/loadStores",
    async ({ referenceDate, partner, selectedStore, productCategoryID }, thunkAPI) => {
        try {
            if (!partner) {
                return [];
            }

            const startDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day');
            const endDate = referenceDate.endOf('day');
            const query = {
                measures: [
                    "ProductSalesEstimate.SumClientSourcedSales",
                    "ProductSalesEstimate.SumEstimatedSales",
                    "ProductSalesEstimate.SumOptimisedSales",
                    "ProductSalesEstimate.SumSalesHeadroom",
                    "ProductSalesEstimate.AvgConfidenceInterval"
                ],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [startDate, endDate]
                }],
                dimensions: [
                    "D_Store.StoreNaturalID",
                    "D_Store.StoreName",
                    "D_Store.AddressLine1",
                    "D_Store.k_Region",
                    "D_Store.ClientRegion",
                    "D_Store.RetailCentreID",
                    "D_Store.Lat",
                    "D_Store.Long",
                    "D_Store.OpeningDate",
                    "D_Store.Sqft",
                    "D_Store.Format"
                ],
                filters: [{
                    member: "D_Store.Group",
                    operator: "equals",
                    values: [partner.name]
                }],
                segments: [
                    "D_Store.OpenPhysicalStores"
                ],
            };

            if (selectedStore) {
                query.filters.push({
                    member: "D_Store.StoreNaturalID",
                    operator: "equals",
                    values: [String(selectedStore.id)]
                });
            }

            if (productCategoryID) {
                query.filters.push({
                    member: "ProductSalesEstimate.FK_ProductCategory",
                    operator: "equals",
                    values: [String(productCategoryID)]
                });
            }

            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;
            const stores = resultSet.rawData().map(row => new Store(
                row["D_Store.StoreNaturalID"],
                row["D_Store.StoreName"],
                row["D_Store.AddressLine1"],
                row["D_Store.k_Region"],
                row["D_Store.ClientRegion"],
                row["D_Store.RetailCentreID"],
                row["D_Store.Lat"],
                row["D_Store.Long"],
                DateTime.fromISO(row["D_Store.OpeningDate"], { zone: "utc" }),
                row["D_Store.Sqft"],
                row["D_Store.Format"],
                Number(row["ProductSalesEstimate.SumClientSourcedSales"]),
                Number(row["ProductSalesEstimate.SumEstimatedSales"]),
                Number(row["ProductSalesEstimate.SumOptimisedSales"]),
                Number(row["ProductSalesEstimate.SumSalesHeadroom"]),
                Number(row["ProductSalesEstimate.AvgConfidenceInterval"])
            ));
            return stores;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading stores."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const loadFilterStores = createAppAsyncThunk<
    Store[],
    { referenceDate: DateTime, partner?: Partner }
>(
    "customer/tools/product/loadFilterStores",
    async ({ referenceDate, partner }, thunkAPI) => {
        try {
            if (!partner) {
                return [];
            }

            const startDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day');
            const endDate = referenceDate.endOf('day');
            const query = {
                measures: [
                    "ProductSalesEstimate.SumClientSourcedSales",
                    "ProductSalesEstimate.SumEstimatedSales",
                    "ProductSalesEstimate.SumOptimisedSales",
                    "ProductSalesEstimate.SumSalesHeadroom",
                    "ProductSalesEstimate.AvgConfidenceInterval"
                ],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [startDate, endDate]
                }],
                dimensions: [
                    "D_Store.StoreNaturalID",
                    "D_Store.StoreName",
                    "D_Store.AddressLine1",
                    "D_Store.k_Region",
                    "D_Store.ClientRegion",
                    "D_Store.RetailCentreID",
                    "D_Store.Lat",
                    "D_Store.Long",
                    "D_Store.OpeningDate",
                    "D_Store.Sqft",
                    "D_Store.Format"
                ],
                filters: [{
                    member: "D_Store.Group",
                    operator: "equals",
                    values: [partner.name]
                }],
                segments: [
                    "D_Store.OpenPhysicalStores"
                ],
            };

            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;
            const stores = resultSet.rawData().map(row => new Store(
                row["D_Store.StoreNaturalID"],
                row["D_Store.StoreName"],
                row["D_Store.AddressLine1"],
                row["D_Store.k_Region"],
                row["D_Store.ClientRegion"],
                row["D_Store.RetailCentreID"],
                row["D_Store.Lat"],
                row["D_Store.Long"],
                DateTime.fromISO(row["D_Store.OpeningDate"], { zone: "utc" }),
                row["D_Store.Sqft"],
                row["D_Store.Format"],
                Number(row["ProductSalesEstimate.SumClientSourcedSales"]),
                Number(row["ProductSalesEstimate.SumEstimatedSales"]),
                Number(row["ProductSalesEstimate.SumOptimisedSales"]),
                Number(row["ProductSalesEstimate.SumSalesHeadroom"]),
                Number(row["ProductSalesEstimate.AvgConfidenceInterval"])
            ));
            return stores;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading filter stores."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
