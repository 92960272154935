import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createAppAsyncThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface ApplicationInsights {
    instrumentationKey: string
}

interface AzureCdn {
    sayGoodbyeToGutFeelVideoUrl: string,
    onboardingVideoUrl: string,
    nwaaTestimonialVideoUrl: string
}

interface GoogleAnalytics {
    measurementId: string
}

interface GoogleRecaptcha {
    siteKey: string
}

interface Mapbox {
    accessToken: string,
    baseMapStyle: string,
    regionsTilesetId: string,
    regionsTilesetUrl: string,
    outputAreasTilesetId: string,
    outputAreasTilesetUrl: string,
    localAuthoritiesTilesetId: string,
    localAuthoritiesTilesetUrl: string,
    lowerLayerSuperOutputAreasTilesetId: string,
    lowerLayerSuperOutputAreasTilesetUrl: string,
    retailCentresTilesetId: string,
    retailCentresTilesetUrl: string
    retailPetalsTilesetId: string,
    retailPetalsTilesetUrl: string
}

interface Stripe {
    publicKey: string
}

interface SiteConfigurationState {
    isLoaded: boolean,
    applicationInsights: ApplicationInsights,
    azureCdn: AzureCdn,
    googleAnalytics: GoogleAnalytics,
    googleRecaptcha: GoogleRecaptcha,
    mapbox: Mapbox,
    stripe: Stripe
}

const initialState: SiteConfigurationState = {
    isLoaded: false,
    applicationInsights: {
        instrumentationKey: ""
    },
    azureCdn: {
        sayGoodbyeToGutFeelVideoUrl: "",
        onboardingVideoUrl: "",
        nwaaTestimonialVideoUrl: ""
    },
    googleAnalytics: {
        measurementId: ""
    },
    googleRecaptcha: {
        siteKey: ""
    },
    mapbox: {
        accessToken: "",
        baseMapStyle: "",
        regionsTilesetId: "",
        regionsTilesetUrl: "",
        outputAreasTilesetId: "",
        outputAreasTilesetUrl: "",
        localAuthoritiesTilesetId: "",
        localAuthoritiesTilesetUrl: "",
        lowerLayerSuperOutputAreasTilesetId: "",
        lowerLayerSuperOutputAreasTilesetUrl: "",
        retailCentresTilesetId: "",
        retailCentresTilesetUrl: "",
        retailPetalsTilesetId: "",
        retailPetalsTilesetUrl: ""
    },
    stripe: {
        publicKey: ""
    }
};

const siteConfigurationSlice = createSlice({
    name: "siteConfiguration",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getSiteConfiguration.pending, (state: SiteConfigurationState) => {
            state.isLoaded = false;
        });
        builder.addCase(getSiteConfiguration.rejected, (state: SiteConfigurationState) => {
            state.isLoaded = true;
        });
        builder.addCase(getSiteConfiguration.fulfilled, (state: SiteConfigurationState, action: PayloadAction<SiteConfigurationState>) => {
            state.isLoaded = true;
            state.applicationInsights = action.payload.applicationInsights;
            state.azureCdn = action.payload.azureCdn;
            state.googleAnalytics = action.payload.googleAnalytics;
            state.googleRecaptcha = action.payload.googleRecaptcha;
            state.mapbox = action.payload.mapbox;
            state.stripe = action.payload.stripe;
        });
    }
});

export const getSiteConfiguration = createAppAsyncThunk(
    "siteConfiguration/getSiteConfiguration",
    async (arg, thunkAPI) => {
        const response = await thunkAPI.dispatch(apiGet("/site-configuration"));
        if (response.status === ApiResponseStatus.Ok) {
            return response.data;
        }

        thunkAPI.dispatch(notifyError("Error loading site configuration."));
        return thunkAPI.rejectWithValue(null);
    }
);

export const selectIsSiteConfigurationLoaded = (state: RootState): boolean => {
    return state.siteConfiguration.isLoaded;
};

export const selectApplicationInsightsConfiguration = (state: RootState): ApplicationInsights => {
    return state.siteConfiguration.applicationInsights;
};

export const selectAzureCdnConfiguration = (state: RootState): AzureCdn => {
    return state.siteConfiguration.azureCdn;
};

export const selectGoogleAnalyticsConfiguration = (state: RootState): GoogleAnalytics => {
    return state.siteConfiguration.googleAnalytics;
};

export const selectGoogleRecaptchaConfiguration = (state: RootState): GoogleRecaptcha => {
    return state.siteConfiguration.googleRecaptcha;
};

export const selectMapboxConfiguration = (state: RootState): Mapbox => {
    return state.siteConfiguration.mapbox;
};

export const selectStripeConfiguration = (state: RootState): Stripe => {
    return state.siteConfiguration.stripe;
};

export default siteConfigurationSlice;
